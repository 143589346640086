// src/theme.js
import { createTheme } from '@mui/material/styles';

// Define the custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1565c0', // Blue tone
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#607d8b', // Grey tone
      contrastText: '#ffffff',
    },
    accent: {
      main: '#ff9800', // Orange accent
      contrastText: '#ffffff',
    },
    background: {
      default: '#f4f6f8', // Light grey background
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '2rem',
      color: '#333',
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.75rem',
      color: '#333',
    },
    body1: {
      fontSize: '1rem',
      color: '#555',
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 0, // Rounded corners for components
  },
  spacing: 8, // Default spacing
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
        padding: '6px 16px',
        '&:hover': {
          backgroundColor: '#005cb2',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 4,
        backgroundColor: '#e0f7fa',
        color: '#006064',
        '& .MuiChip-deleteIcon': {
          color: '#004d40',
        },
      },
    },
  },
});

export default theme;

