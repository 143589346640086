// src/components/TabularData.js
import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';

const TabularData = ({ results }) => {
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'trial_title', headerName: 'Trial Title', width: 150 },
    { field: 'trial_phase', headerName: 'Trial Phase', width: 110 },
    { field: 'trial_status', headerName: 'Trial Status', width: 150 },
    { field: 'sponsor', headerName: 'Sponsor', width: 150 },
    { field: 'therapeutic_area', headerName: 'Therapeutic Area', width: 150 },
    { field: 'disease', headerName: 'Disease', width: 150 },
    { field: 'patient_segment', headerName: 'Patient Segment', width: 150 },
    { field: 'primary_drug', headerName: 'Primary Drug', width: 150 },
    { field: 'countries', headerName: 'Countries', width: 150 },
    { field: 'trial_outcome', headerName: 'Trial Outcome', width: 150 },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid rows={results} columns={columns} pageSize={5} />
    </div>
  );
};

TabularData.propTypes = {
  results: PropTypes.array.isRequired,
};

export default TabularData;
