// src/components/QueryContainer.js
import React, { useContext, useState, useEffect } from 'react';
import { Box, Chip, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { SelectedOptionsContext } from '../context/SelectedOptionsContext';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const QueryContainer = ({ runSearch }) => {
  const { selectedOptions, setSelectedOptions } = useContext(SelectedOptionsContext);

  // Group the selected options by their node labels
  const groupedOptions = selectedOptions.reduce((acc, option) => {
    if (!acc[option.nodeLabel]) {
      acc[option.nodeLabel] = [];
    }
    acc[option.nodeLabel].push(option);
    return acc;
  }, {});

  // State for managing the dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentOption, setCurrentOption] = useState(null);

  // State for connector type between sub-containers
  const [connectorType, setConnectorType] = useState('AND'); // Default to 'AND'

  // Function to handle removing a tag
  const handleRemoveTag = (id, nodeLabel) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter(option => !(option.id === id && option.nodeLabel === nodeLabel))
    );
  };

  // Function to handle toggling between AND/OR for sub-container connectors
  const toggleConnector = (index, nodeLabel) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      const group = newOptions.filter(option => option.nodeLabel === nodeLabel);
      if (group[index]) {
        group[index].connector = group[index].connector === 'AND' ? 'OR' : 'AND';
      }
      return newOptions;
    });
  };

  // Function to handle toggling between AND/OR for individual query terms
  const toggleQueryType = (optionId, nodeLabel) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === optionId && option.nodeLabel === nodeLabel
          ? { ...option, queryType: option.queryType === 'AND' ? 'OR' : 'AND' }
          : option
      )
    );
  };

  // Function to handle opening the dropdown menu
  const handleClick = (event, option) => {
    setAnchorEl(event.currentTarget);
    setCurrentOption(option);
  };

  // Function to handle closing the dropdown menu
  const handleClose = () => {
    setAnchorEl(null);
    setCurrentOption(null);
  };

  // Function to handle selecting a dropdown option
  const handleMenuItemClick = (event, option, selectedOption) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.map((opt) =>
        opt.id === option.id && opt.nodeLabel === option.nodeLabel
          ? { ...opt, operator: selectedOption }
          : opt
      )
    );
    handleClose();
  };

  // Function to toggle the connector type between sub-containers
  const toggleConnectorType = () => {
    setConnectorType(connectorType === 'AND' ? 'OR' : 'AND');
  };

  // Effect to run the search whenever selectedOptions change
  useEffect(() => {
    runSearch(selectedOptions);
  }, [selectedOptions, runSearch]);

  return (
    <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 2, marginBottom: 2 }}>
      {Object.keys(groupedOptions).length > 0 ? (
        Object.keys(groupedOptions).map((nodeLabel, idx) => (
          <Box key={nodeLabel} sx={{ position: 'relative', marginBottom: 2 }}>
            <Box sx={{ border: '1px solid #ddd', padding: 1, borderRadius: 1 }}>
              {groupedOptions[nodeLabel].map((option, index) => (
                <Box key={option.id} sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Chip
                      label={(
                        <>
                          <span>{nodeLabel}</span> {/* Display the node label (modal title) */}
                          <Button
                            onClick={(e) => handleClick(e, option)}
                            sx={{ marginLeft: 1, marginRight: 1 }}
                            size="small"
                            variant="outlined"
                          >
                            {option.operator || 'is'}
                          </Button>
                          <span>{option.entry_name}</span>
                        </>
                      )}
                      onDelete={() => handleRemoveTag(option.id, nodeLabel)}
                      sx={{ margin: 0.5 }}
                      icon={
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleQueryType(option.id, nodeLabel);
                          }}
                        >
                          <SwapVertIcon />
                        </IconButton>
                      }
                    />
                  </Box>
                  {index < groupedOptions[nodeLabel].length - 1 && (
                    <Button
                      onClick={() => toggleConnector(index, nodeLabel)}
                      sx={{ marginLeft: 1 }}
                    >
                      {groupedOptions[nodeLabel][index].connector || 'OR'} {/* Default to OR */}
                    </Button>
                  )}
                </Box>
              ))}
            </Box>

            {/* Connector between sub-containers */}
            {idx < Object.keys(groupedOptions).length - 1 && (
              <Box sx={{ position: 'relative', textAlign: 'center', marginY: 2 }}>
                {/* Vertical lines above and below the button */}
                <Box sx={{ position: 'absolute', top: '-20px', left: '50%', transform: 'translateX(-50%)', height: '20px', borderLeft: '2px solid #ccc' }}></Box>
                <Button
                  variant="contained"
                  size="small"
                  onClick={toggleConnectorType}
                  sx={{ zIndex: 1, marginY: 1 }}
                >
                  {connectorType}
                </Button>
                <Box sx={{ position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)', height: '20px', borderLeft: '2px solid #ccc' }}></Box>
              </Box>
            )}
          </Box>
        ))
      ) : (
        <Typography>No query terms selected.</Typography>
      )}

      {/* Dropdown menu for selecting operators */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {['is', 'is not', 'contains', 'does not contain'].map((option) => (
          <MenuItem
            key={option}
            onClick={(event) => handleMenuItemClick(event, currentOption, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default QueryContainer;
