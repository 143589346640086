// src/App.js
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import SidebarTree from './components/SidebarTree';
import OptionsModal from './components/OptionsModal';
import QueryContainer from './components/QueryContainer';
import ResultsContainer from './components/ResultsContainer';
import { SelectedOptionsProvider } from './context/SelectedOptionsContext';
import { useNavigate } from 'react-router-dom';
import { ContextHolder, FronteggContext } from "@frontegg/rest-api";


import {
  useAuth,
  useLoginWithRedirect,
  AdminPortal,
  useTenantsState
} from "@frontegg/react";


const App = () => {
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeLabel, setSelectedNodeLabel] = useState('');
  const [searchResults, setSearchResults] = useState([]); // State to store search results

  const { isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();
  const navigate = useNavigate();

  useEffect(() => {
  if (!isAuthenticated) {
            console.log('not authenticated');

     localStorage.setItem('_REDIRECT_AFTER_LOGIN_', window.location.pathname);
     loginWithRedirect();
  } 
    const fetchTreeData = async () => {
      try {
        const response = await axios.get('https://x8ki-letl-twmt.n7.xano.io/api:GAVT67Xi/trialsearchtree');
        const data = transformData(response.data);
        setTreeData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tree data:', error);
        setError('Failed to load tree data');
        setLoading(false);
      }
    };

    fetchTreeData();
  }, [isAuthenticated, loginWithRedirect]);

  const logout = () => {
//   const baseUrl = ContextHolder.getContext().baseUrl;
 //  window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  };
  
   const originalRoute = localStorage.getItem('_REDIRECT_AFTER_LOGIN_');
  const token = FronteggContext.getAccessToken();

  if (isAuthenticated && originalRoute) {
    localStorage.removeItem('_REDIRECT_AFTER_LOGIN_');
    navigate(originalRoute);
  }

  const transformData = (data) => {
    const tree = [];
    const lookup = {};

    data.forEach((node) => {
      if (!node.nodeId) {
        console.error('Node is missing an id:', node);
        return;
      }
      lookup[node.nodeId] = { ...node, children: [] };
    });

    data.forEach((node) => {
      if (node.parent_id === null || node.parent_id === 'NULL' || node.parent_id === undefined) {
        tree.push(lookup[node.nodeId]);
      } else {
        if (lookup[node.parent_id]) {
          lookup[node.parent_id].children.push(lookup[node.nodeId]);
        } else {
          console.error(`Parent id ${node.parent_id} not found for node:`, node);
        }
      }
    });

    return tree;
  };

  const handleNodeSelect = (nodeId, nodeLabel) => {
    setSelectedNode(nodeId);
    setSelectedNodeLabel(nodeLabel);
    setModalOpen(true);
  };

  // Updated function to run the search based on the selected query terms
  const runSearch = async (queryTerms) => {
    // Check if there are any query terms selected
    if (!queryTerms || queryTerms.length === 0) {
      console.log('No query terms selected, skipping API call.');
      return;
    }

    try {
      const query = queryTerms.map(option => `${option.nodeLabel}:${option.entry_name}`).join(',');
      // Call the API with the query
      const response = await axios.get(`https://x8ki-letl-twmt.n7.xano.io/api:GAVT67Xi/trials?drugName=Amoxicillin`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error running search:', error);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <SelectedOptionsProvider>
      <Box sx={{ display: 'flex', padding: 2 }}>
        <Box sx={{ width: '250px' }}>
          <SidebarTree nodes={treeData} onNodeSelect={handleNodeSelect} />
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
          <QueryContainer runSearch={runSearch} /> {/* Pass the runSearch function */}
          <ResultsContainer results={searchResults} /> {/* Pass the search results */}
        </Box>
        {selectedNode && (
          <OptionsModal
            open={modalOpen}
            onClose={() => setModalOpen(false)} // Function to close the modal
            nodeId={selectedNode}
            nodeLabel={selectedNodeLabel} // Pass the node label to the modal
          />
        )}
      </Box>
    </SelectedOptionsProvider>
  );
};

export default App;
