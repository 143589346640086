// src/components/SidebarTree.js
import React, { useState } from 'react';
import { Box } from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { ExpandMore, ChevronRight } from '@mui/icons-material';
import OptionsModal from './OptionsModal';

const SidebarTree = ({ nodes }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeLabel, setSelectedNodeLabel] = useState(''); // Add state to store nodeLabel

  // Function to determine if the nodeId is for a child node
  const isChildNode = (nodeId) => {
    // A nodeId with a dot indicates it's a child node (e.g., '1.1', '2.3')
    return nodeId.includes('.');
  };

  // Event handler for node selection
  const handleNodeSelect = (event, nodeId, nodeLabel) => {
    console.log('Node selected:', nodeId); // Debug log to check if this is called
    if (isChildNode(nodeId)) {
      setSelectedNode(nodeId); // Store the selected node ID if it's a child
      setSelectedNodeLabel(nodeLabel); // Store the node label
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedNode(null); // Clear the selected node ID when closing the modal
    setSelectedNodeLabel(''); // Clear the selected node label when closing the modal
  };

  // Function to render the tree recursively
  const renderTree = (nodes) => (
    <TreeItem 
      key={nodes.id} 
      itemId={nodes.nodeId.toString()} 
      label={nodes.label}
      onClick={() => handleNodeSelect(null, nodes.nodeId.toString(), nodes.label)} // Pass both nodeId and nodeLabel
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <Box sx={{ width: 250, padding: 2 }}>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
      >
        {nodes.map((node) => renderTree(node))}
      </TreeView>
      <OptionsModal
        open={modalOpen}
        onClose={handleCloseModal}
        nodeId={selectedNode}
        nodeLabel={selectedNodeLabel} // Pass the node label to the modal
      />
    </Box>
  );
};

export default SidebarTree;
