// src/components/OptionsModal.js
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Box, TextField, Typography, List, ListItem, ListItemText, Checkbox, CircularProgress, useMediaQuery, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import PropTypes from 'prop-types';
import { SelectedOptionsContext } from '../context/SelectedOptionsContext'; // Import the context

const OptionsModal = ({ open, onClose, nodeId, nodeLabel }) => {
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

  // Use context to manage selected options globally
  const { selectedOptions, setSelectedOptions } = useContext(SelectedOptionsContext);

  useEffect(() => {
    if (open && nodeId) {
      setLoading(true);
      const fetchOptions = async () => {
        try {
          const response = await axios.get(`https://x8ki-letl-twmt.n7.xano.io/api:GAVT67Xi/modal?node_id=${nodeId}`);
          setOptions(response.data);
          setFilteredOptions(response.data);
        } catch (error) {
          console.error('Error fetching options:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchOptions();
    }
  }, [open, nodeId]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = options.filter(option =>
        option.entry_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  }, [searchTerm, options]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggle = (option) => {
    // Find the index of the current option in the selectedOptions
    const currentIndex = selectedOptions.findIndex(item => item.id === option.id && item.nodeLabel === nodeLabel);
    let newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      // Add the option if it doesn't already exist
      newSelectedOptions.push({ ...option, nodeLabel });
    } else {
      // Remove the option if it already exists
      newSelectedOptions.splice(currentIndex, 1);
    }

    // Update the context with new selections
    setSelectedOptions(newSelectedOptions);
  };

  const handleModalClose = () => {
    onClose(); // Close the modal when called
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: 'absolute',
          top: isMobile ? 0 : '50%',
          left: isMobile ? 0 : '50%',
          transform: isMobile ? 'none' : 'translate(-50%, -50%)',
          width: isMobile ? '100%' : 500,
          height: isMobile ? '100%' : 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          borderRadius: 0,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="h2">
            {nodeLabel} Options {/* Display the node label as the modal title */}
          </Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={`Search ${nodeLabel}...`} // Add nodeLabel to the placeholder for context
          value={searchTerm}
          onChange={handleSearchChange}
          margin="normal"
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <List>
            {filteredOptions.sort((a, b) => {
              const isSelectedA = selectedOptions.some(item => item.id === a.id && item.nodeLabel === nodeLabel);
              const isSelectedB = selectedOptions.some(item => item.id === b.id && item.nodeLabel === nodeLabel);
              if (isSelectedA && !isSelectedB) return -1;
              if (!isSelectedA && isSelectedB) return 1;
              return 0;
            }).map((option) => (
              <ListItem 
                button 
                key={option.id} 
                onClick={() => handleToggle(option)}
              >
                <Checkbox
                  checked={selectedOptions.some(item => item.id === option.id && item.nodeLabel === nodeLabel)}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the list item click event
                    handleToggle(option);
                  }}
                />
                <ListItemText primary={option.entry_name} />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Modal>
  );
};

OptionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  nodeId: PropTypes.string,
  nodeLabel: PropTypes.string, // Prop for the node label to show in the modal title
};

export default OptionsModal;
