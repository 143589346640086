// src/components/ResultsContainer.js
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import TabularData from './TabularData';

const ResultsContainer = ({ results }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs value={value} onChange={handleChange} aria-label="results tabs">
        <Tab label="Table View" />
        <Tab label="Dashboard 1" />
        <Tab label="Dashboard 2" />
      </Tabs>
      {value === 0 && (
        <Box>
          <TabularData results={results} />
        </Box>
      )}
      {value === 1 && <Typography>Dashboard 1 Content</Typography>}
      {value === 2 && <Typography>Dashboard 2 Content</Typography>}
    </Box>
  );
};

ResultsContainer.propTypes = {
  results: PropTypes.array.isRequired,
};

export default ResultsContainer;
