// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import { ThemeProvider, CssBaseline } from '@mui/material';
import App from './App';
import theme from './theme';
import { FronteggProvider } from '@frontegg/react';

// Find the root element where your app will be mounted
const container = document.getElementById('root');

// Create a root using the new React 18 API
//const root = ReactDOM.createRoot(container);


const contextOptions = {
   baseUrl: 'https://auth.ignitionbio.com',
  clientId: 'eb50fc6d-05a8-44f9-9305-6462ea7e4dfe'
};

const authOptions = {
 // keepSessionAlive: true // Uncomment this in order to maintain the session alive
};


const root = ReactDOM.createRoot(document.getElementById('root'));


// Render the application with the ThemeProvider and CssBaseline
//root.render(
//  <ThemeProvider theme={theme}>
//    <CssBaseline /> {}
//    <App />
//  </ThemeProvider>
//);

root.render(
    <FronteggProvider 
    contextOptions={contextOptions} 
    hostedLoginBox={true}
    authOptions={authOptions}>
        <App />
    </FronteggProvider>,
    document.getElementById('root')
);
