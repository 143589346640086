// src/context/SelectedOptionsContext.js
import React, { createContext, useState } from 'react';

// Create a Context for the selected options
export const SelectedOptionsContext = createContext();

// Create a Provider component
export const SelectedOptionsProvider = ({ children }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  return (
    <SelectedOptionsContext.Provider value={{ selectedOptions, setSelectedOptions }}>
      {children}
    </SelectedOptionsContext.Provider>
  );
};
